/**
 * Font Awesome Solid, 5.13.0
 * Created by Dave Gandy
 * https://fontawesome.com
 * (CC-BY-4.0 AND MIT) License
 */
@charset "UTF-8";

@font-face {
  font-family: "fontawesome-solid";
  src: url('./fontawesome-solid.eot');
  src: 
    url('./fontawesome-solid.eot?#iefix') format('embedded-opentype'),
    url('./fontawesome-solid.woff2') format('woff2'),
    url('./fontawesome-solid.woff') format('woff'),
    url('./fontawesome-solid.ttf') format('truetype'),
    url('./fontawesome-solid.svg#fontawesome-solid') format('svg');
}

.fas {
  font-family: "fontawesome-solid";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
}

.fas-ad::before {
  content: "\ea01";
}

.fas-address-book::before {
  content: "\ea02";
}

.fas-address-card::before {
  content: "\ea03";
}

.fas-adjust::before {
  content: "\ea04";
}

.fas-air-freshener::before {
  content: "\ea05";
}

.fas-align-center::before {
  content: "\ea06";
}

.fas-align-justify::before {
  content: "\ea07";
}

.fas-align-left::before {
  content: "\ea08";
}

.fas-align-right::before {
  content: "\ea09";
}

.fas-allergies::before {
  content: "\ea0a";
}

.fas-ambulance::before {
  content: "\ea0b";
}

.fas-american-sign-language-interpreting::before {
  content: "\ea0c";
}

.fas-anchor::before {
  content: "\ea0d";
}

.fas-angle-double-down::before {
  content: "\ea0e";
}

.fas-angle-double-left::before {
  content: "\ea0f";
}

.fas-angle-double-right::before {
  content: "\ea10";
}

.fas-angle-double-up::before {
  content: "\ea11";
}

.fas-angle-down::before {
  content: "\ea12";
}

.fas-angle-left::before {
  content: "\ea13";
}

.fas-angle-right::before {
  content: "\ea14";
}

.fas-angle-up::before {
  content: "\ea15";
}

.fas-angry::before {
  content: "\ea16";
}

.fas-ankh::before {
  content: "\ea17";
}

.fas-apple-alt::before {
  content: "\ea18";
}

.fas-archive::before {
  content: "\ea19";
}

.fas-archway::before {
  content: "\ea1a";
}

.fas-arrow-alt-circle-down::before {
  content: "\ea1b";
}

.fas-arrow-alt-circle-left::before {
  content: "\ea1c";
}

.fas-arrow-alt-circle-right::before {
  content: "\ea1d";
}

.fas-arrow-alt-circle-up::before {
  content: "\ea1e";
}

.fas-arrow-circle-down::before {
  content: "\ea1f";
}

.fas-arrow-circle-left::before {
  content: "\ea20";
}

.fas-arrow-circle-right::before {
  content: "\ea21";
}

.fas-arrow-circle-up::before {
  content: "\ea22";
}

.fas-arrow-down::before {
  content: "\ea23";
}

.fas-arrow-left::before {
  content: "\ea24";
}

.fas-arrow-right::before {
  content: "\ea25";
}

.fas-arrow-up::before {
  content: "\ea26";
}

.fas-arrows-alt::before {
  content: "\ea27";
}

.fas-arrows-alt-h::before {
  content: "\ea28";
}

.fas-arrows-alt-v::before {
  content: "\ea29";
}

.fas-assistive-listening-systems::before {
  content: "\ea2a";
}

.fas-asterisk::before {
  content: "\ea2b";
}

.fas-at::before {
  content: "\ea2c";
}

.fas-atlas::before {
  content: "\ea2d";
}

.fas-atom::before {
  content: "\ea2e";
}

.fas-audio-description::before {
  content: "\ea2f";
}

.fas-award::before {
  content: "\ea30";
}

.fas-baby::before {
  content: "\ea31";
}

.fas-baby-carriage::before {
  content: "\ea32";
}

.fas-backspace::before {
  content: "\ea33";
}

.fas-backward::before {
  content: "\ea34";
}

.fas-bacon::before {
  content: "\ea35";
}

.fas-bahai::before {
  content: "\ea36";
}

.fas-balance-scale::before {
  content: "\ea37";
}

.fas-balance-scale-left::before {
  content: "\ea38";
}

.fas-balance-scale-right::before {
  content: "\ea39";
}

.fas-ban::before {
  content: "\ea3a";
}

.fas-band-aid::before {
  content: "\ea3b";
}

.fas-barcode::before {
  content: "\ea3c";
}

.fas-bars::before {
  content: "\ea3d";
}

.fas-baseball-ball::before {
  content: "\ea3e";
}

.fas-basketball-ball::before {
  content: "\ea3f";
}

.fas-bath::before {
  content: "\ea40";
}

.fas-battery-empty::before {
  content: "\ea41";
}

.fas-battery-full::before {
  content: "\ea42";
}

.fas-battery-half::before {
  content: "\ea43";
}

.fas-battery-quarter::before {
  content: "\ea44";
}

.fas-battery-three-quarters::before {
  content: "\ea45";
}

.fas-bed::before {
  content: "\ea46";
}

.fas-beer::before {
  content: "\ea47";
}

.fas-bell::before {
  content: "\ea48";
}

.fas-bell-slash::before {
  content: "\ea49";
}

.fas-bezier-curve::before {
  content: "\ea4a";
}

.fas-bible::before {
  content: "\ea4b";
}

.fas-bicycle::before {
  content: "\ea4c";
}

.fas-biking::before {
  content: "\ea4d";
}

.fas-binoculars::before {
  content: "\ea4e";
}

.fas-biohazard::before {
  content: "\ea4f";
}

.fas-birthday-cake::before {
  content: "\ea50";
}

.fas-blender::before {
  content: "\ea51";
}

.fas-blender-phone::before {
  content: "\ea52";
}

.fas-blind::before {
  content: "\ea53";
}

.fas-blog::before {
  content: "\ea54";
}

.fas-bold::before {
  content: "\ea55";
}

.fas-bolt::before {
  content: "\ea56";
}

.fas-bomb::before {
  content: "\ea57";
}

.fas-bone::before {
  content: "\ea58";
}

.fas-bong::before {
  content: "\ea59";
}

.fas-book::before {
  content: "\ea5a";
}

.fas-book-dead::before {
  content: "\ea5b";
}

.fas-book-medical::before {
  content: "\ea5c";
}

.fas-book-open::before {
  content: "\ea5d";
}

.fas-book-reader::before {
  content: "\ea5e";
}

.fas-bookmark::before {
  content: "\ea5f";
}

.fas-border-all::before {
  content: "\ea60";
}

.fas-border-none::before {
  content: "\ea61";
}

.fas-border-style::before {
  content: "\ea62";
}

.fas-bowling-ball::before {
  content: "\ea63";
}

.fas-box::before {
  content: "\ea64";
}

.fas-box-open::before {
  content: "\ea65";
}

.fas-box-tissue::before {
  content: "\ea66";
}

.fas-boxes::before {
  content: "\ea67";
}

.fas-braille::before {
  content: "\ea68";
}

.fas-brain::before {
  content: "\ea69";
}

.fas-bread-slice::before {
  content: "\ea6a";
}

.fas-briefcase::before {
  content: "\ea6b";
}

.fas-briefcase-medical::before {
  content: "\ea6c";
}

.fas-broadcast-tower::before {
  content: "\ea6d";
}

.fas-broom::before {
  content: "\ea6e";
}

.fas-brush::before {
  content: "\ea6f";
}

.fas-bug::before {
  content: "\ea70";
}

.fas-building::before {
  content: "\ea71";
}

.fas-bullhorn::before {
  content: "\ea72";
}

.fas-bullseye::before {
  content: "\ea73";
}

.fas-burn::before {
  content: "\ea74";
}

.fas-bus::before {
  content: "\ea75";
}

.fas-bus-alt::before {
  content: "\ea76";
}

.fas-business-time::before {
  content: "\ea77";
}

.fas-calculator::before {
  content: "\ea78";
}

.fas-calendar::before {
  content: "\ea79";
}

.fas-calendar-alt::before {
  content: "\ea7a";
}

.fas-calendar-check::before {
  content: "\ea7b";
}

.fas-calendar-day::before {
  content: "\ea7c";
}

.fas-calendar-minus::before {
  content: "\ea7d";
}

.fas-calendar-plus::before {
  content: "\ea7e";
}

.fas-calendar-times::before {
  content: "\ea7f";
}

.fas-calendar-week::before {
  content: "\ea80";
}

.fas-camera::before {
  content: "\ea81";
}

.fas-camera-retro::before {
  content: "\ea82";
}

.fas-campground::before {
  content: "\ea83";
}

.fas-candy-cane::before {
  content: "\ea84";
}

.fas-cannabis::before {
  content: "\ea85";
}

.fas-capsules::before {
  content: "\ea86";
}

.fas-car::before {
  content: "\ea87";
}

.fas-car-alt::before {
  content: "\ea88";
}

.fas-car-battery::before {
  content: "\ea89";
}

.fas-car-crash::before {
  content: "\ea8a";
}

.fas-car-side::before {
  content: "\ea8b";
}

.fas-caravan::before {
  content: "\ea8c";
}

.fas-caret-down::before {
  content: "\ea8d";
}

.fas-caret-left::before {
  content: "\ea8e";
}

.fas-caret-right::before {
  content: "\ea8f";
}

.fas-caret-square-down::before {
  content: "\ea90";
}

.fas-caret-square-left::before {
  content: "\ea91";
}

.fas-caret-square-right::before {
  content: "\ea92";
}

.fas-caret-square-up::before {
  content: "\ea93";
}

.fas-caret-up::before {
  content: "\ea94";
}

.fas-carrot::before {
  content: "\ea95";
}

.fas-cart-arrow-down::before {
  content: "\ea96";
}

.fas-cart-plus::before {
  content: "\ea97";
}

.fas-cash-register::before {
  content: "\ea98";
}

.fas-cat::before {
  content: "\ea99";
}

.fas-certificate::before {
  content: "\ea9a";
}

.fas-chair::before {
  content: "\ea9b";
}

.fas-chalkboard::before {
  content: "\ea9c";
}

.fas-chalkboard-teacher::before {
  content: "\ea9d";
}

.fas-charging-station::before {
  content: "\ea9e";
}

.fas-chart-area::before {
  content: "\ea9f";
}

.fas-chart-bar::before {
  content: "\eaa0";
}

.fas-chart-line::before {
  content: "\eaa1";
}

.fas-chart-pie::before {
  content: "\eaa2";
}

.fas-check::before {
  content: "\eaa3";
}

.fas-check-circle::before {
  content: "\eaa4";
}

.fas-check-double::before {
  content: "\eaa5";
}

.fas-check-square::before {
  content: "\eaa6";
}

.fas-cheese::before {
  content: "\eaa7";
}

.fas-chess::before {
  content: "\eaa8";
}

.fas-chess-bishop::before {
  content: "\eaa9";
}

.fas-chess-board::before {
  content: "\eaaa";
}

.fas-chess-king::before {
  content: "\eaab";
}

.fas-chess-knight::before {
  content: "\eaac";
}

.fas-chess-pawn::before {
  content: "\eaad";
}

.fas-chess-queen::before {
  content: "\eaae";
}

.fas-chess-rook::before {
  content: "\eaaf";
}

.fas-chevron-circle-down::before {
  content: "\eab0";
}

.fas-chevron-circle-left::before {
  content: "\eab1";
}

.fas-chevron-circle-right::before {
  content: "\eab2";
}

.fas-chevron-circle-up::before {
  content: "\eab3";
}

.fas-chevron-down::before {
  content: "\eab4";
}

.fas-chevron-left::before {
  content: "\eab5";
}

.fas-chevron-right::before {
  content: "\eab6";
}

.fas-chevron-up::before {
  content: "\eab7";
}

.fas-child::before {
  content: "\eab8";
}

.fas-church::before {
  content: "\eab9";
}

.fas-circle::before {
  content: "\eaba";
}

.fas-circle-notch::before {
  content: "\eabb";
}

.fas-city::before {
  content: "\eabc";
}

.fas-clinic-medical::before {
  content: "\eabd";
}

.fas-clipboard::before {
  content: "\eabe";
}

.fas-clipboard-check::before {
  content: "\eabf";
}

.fas-clipboard-list::before {
  content: "\eac0";
}

.fas-clock::before {
  content: "\eac1";
}

.fas-clone::before {
  content: "\eac2";
}

.fas-closed-captioning::before {
  content: "\eac3";
}

.fas-cloud::before {
  content: "\eac4";
}

.fas-cloud-download-alt::before {
  content: "\eac5";
}

.fas-cloud-meatball::before {
  content: "\eac6";
}

.fas-cloud-moon::before {
  content: "\eac7";
}

.fas-cloud-moon-rain::before {
  content: "\eac8";
}

.fas-cloud-rain::before {
  content: "\eac9";
}

.fas-cloud-showers-heavy::before {
  content: "\eaca";
}

.fas-cloud-sun::before {
  content: "\eacb";
}

.fas-cloud-sun-rain::before {
  content: "\eacc";
}

.fas-cloud-upload-alt::before {
  content: "\eacd";
}

.fas-cocktail::before {
  content: "\eace";
}

.fas-code::before {
  content: "\eacf";
}

.fas-code-branch::before {
  content: "\ead0";
}

.fas-coffee::before {
  content: "\ead1";
}

.fas-cog::before {
  content: "\ead2";
}

.fas-cogs::before {
  content: "\ead3";
}

.fas-coins::before {
  content: "\ead4";
}

.fas-columns::before {
  content: "\ead5";
}

.fas-comment::before {
  content: "\ead6";
}

.fas-comment-alt::before {
  content: "\ead7";
}

.fas-comment-dollar::before {
  content: "\ead8";
}

.fas-comment-dots::before {
  content: "\ead9";
}

.fas-comment-medical::before {
  content: "\eada";
}

.fas-comment-slash::before {
  content: "\eadb";
}

.fas-comments::before {
  content: "\eadc";
}

.fas-comments-dollar::before {
  content: "\eadd";
}

.fas-compact-disc::before {
  content: "\eade";
}

.fas-compass::before {
  content: "\eadf";
}

.fas-compress::before {
  content: "\eae0";
}

.fas-compress-alt::before {
  content: "\eae1";
}

.fas-compress-arrows-alt::before {
  content: "\eae2";
}

.fas-concierge-bell::before {
  content: "\eae3";
}

.fas-cookie::before {
  content: "\eae4";
}

.fas-cookie-bite::before {
  content: "\eae5";
}

.fas-copy::before {
  content: "\eae6";
}

.fas-copyright::before {
  content: "\eae7";
}

.fas-couch::before {
  content: "\eae8";
}

.fas-credit-card::before {
  content: "\eae9";
}

.fas-crop::before {
  content: "\eaea";
}

.fas-crop-alt::before {
  content: "\eaeb";
}

.fas-cross::before {
  content: "\eaec";
}

.fas-crosshairs::before {
  content: "\eaed";
}

.fas-crow::before {
  content: "\eaee";
}

.fas-crown::before {
  content: "\eaef";
}

.fas-crutch::before {
  content: "\eaf0";
}

.fas-cube::before {
  content: "\eaf1";
}

.fas-cubes::before {
  content: "\eaf2";
}

.fas-cut::before {
  content: "\eaf3";
}

.fas-database::before {
  content: "\eaf4";
}

.fas-deaf::before {
  content: "\eaf5";
}

.fas-democrat::before {
  content: "\eaf6";
}

.fas-desktop::before {
  content: "\eaf7";
}

.fas-dharmachakra::before {
  content: "\eaf8";
}

.fas-diagnoses::before {
  content: "\eaf9";
}

.fas-dice::before {
  content: "\eafa";
}

.fas-dice-d20::before {
  content: "\eafb";
}

.fas-dice-d6::before {
  content: "\eafc";
}

.fas-dice-five::before {
  content: "\eafd";
}

.fas-dice-four::before {
  content: "\eafe";
}

.fas-dice-one::before {
  content: "\eaff";
}

.fas-dice-six::before {
  content: "\eb00";
}

.fas-dice-three::before {
  content: "\eb01";
}

.fas-dice-two::before {
  content: "\eb02";
}

.fas-digital-tachograph::before {
  content: "\eb03";
}

.fas-directions::before {
  content: "\eb04";
}

.fas-disease::before {
  content: "\eb05";
}

.fas-divide::before {
  content: "\eb06";
}

.fas-dizzy::before {
  content: "\eb07";
}

.fas-dna::before {
  content: "\eb08";
}

.fas-dog::before {
  content: "\eb09";
}

.fas-dollar-sign::before {
  content: "\eb0a";
}

.fas-dolly::before {
  content: "\eb0b";
}

.fas-dolly-flatbed::before {
  content: "\eb0c";
}

.fas-donate::before {
  content: "\eb0d";
}

.fas-door-closed::before {
  content: "\eb0e";
}

.fas-door-open::before {
  content: "\eb0f";
}

.fas-dot-circle::before {
  content: "\eb10";
}

.fas-dove::before {
  content: "\eb11";
}

.fas-download::before {
  content: "\eb12";
}

.fas-drafting-compass::before {
  content: "\eb13";
}

.fas-dragon::before {
  content: "\eb14";
}

.fas-draw-polygon::before {
  content: "\eb15";
}

.fas-drum::before {
  content: "\eb16";
}

.fas-drum-steelpan::before {
  content: "\eb17";
}

.fas-drumstick-bite::before {
  content: "\eb18";
}

.fas-dumbbell::before {
  content: "\eb19";
}

.fas-dumpster::before {
  content: "\eb1a";
}

.fas-dumpster-fire::before {
  content: "\eb1b";
}

.fas-dungeon::before {
  content: "\eb1c";
}

.fas-edit::before {
  content: "\eb1d";
}

.fas-egg::before {
  content: "\eb1e";
}

.fas-eject::before {
  content: "\eb1f";
}

.fas-ellipsis-h::before {
  content: "\eb20";
}

.fas-ellipsis-v::before {
  content: "\eb21";
}

.fas-envelope::before {
  content: "\eb22";
}

.fas-envelope-open::before {
  content: "\eb23";
}

.fas-envelope-open-text::before {
  content: "\eb24";
}

.fas-envelope-square::before {
  content: "\eb25";
}

.fas-equals::before {
  content: "\eb26";
}

.fas-eraser::before {
  content: "\eb27";
}

.fas-ethernet::before {
  content: "\eb28";
}

.fas-euro-sign::before {
  content: "\eb29";
}

.fas-exchange-alt::before {
  content: "\eb2a";
}

.fas-exclamation::before {
  content: "\eb2b";
}

.fas-exclamation-circle::before {
  content: "\eb2c";
}

.fas-exclamation-triangle::before {
  content: "\eb2d";
}

.fas-expand::before {
  content: "\eb2e";
}

.fas-expand-alt::before {
  content: "\eb2f";
}

.fas-expand-arrows-alt::before {
  content: "\eb30";
}

.fas-external-link-alt::before {
  content: "\eb31";
}

.fas-external-link-square-alt::before {
  content: "\eb32";
}

.fas-eye::before {
  content: "\eb33";
}

.fas-eye-dropper::before {
  content: "\eb34";
}

.fas-eye-slash::before {
  content: "\eb35";
}

.fas-fan::before {
  content: "\eb36";
}

.fas-fast-backward::before {
  content: "\eb37";
}

.fas-fast-forward::before {
  content: "\eb38";
}

.fas-faucet::before {
  content: "\eb39";
}

.fas-fax::before {
  content: "\eb3a";
}

.fas-feather::before {
  content: "\eb3b";
}

.fas-feather-alt::before {
  content: "\eb3c";
}

.fas-female::before {
  content: "\eb3d";
}

.fas-fighter-jet::before {
  content: "\eb3e";
}

.fas-file::before {
  content: "\eb3f";
}

.fas-file-alt::before {
  content: "\eb40";
}

.fas-file-archive::before {
  content: "\eb41";
}

.fas-file-audio::before {
  content: "\eb42";
}

.fas-file-code::before {
  content: "\eb43";
}

.fas-file-contract::before {
  content: "\eb44";
}

.fas-file-csv::before {
  content: "\eb45";
}

.fas-file-download::before {
  content: "\eb46";
}

.fas-file-excel::before {
  content: "\eb47";
}

.fas-file-export::before {
  content: "\eb48";
}

.fas-file-image::before {
  content: "\eb49";
}

.fas-file-import::before {
  content: "\eb4a";
}

.fas-file-invoice::before {
  content: "\eb4b";
}

.fas-file-invoice-dollar::before {
  content: "\eb4c";
}

.fas-file-medical::before {
  content: "\eb4d";
}

.fas-file-medical-alt::before {
  content: "\eb4e";
}

.fas-file-pdf::before {
  content: "\eb4f";
}

.fas-file-powerpoint::before {
  content: "\eb50";
}

.fas-file-prescription::before {
  content: "\eb51";
}

.fas-file-signature::before {
  content: "\eb52";
}

.fas-file-upload::before {
  content: "\eb53";
}

.fas-file-video::before {
  content: "\eb54";
}

.fas-file-word::before {
  content: "\eb55";
}

.fas-fill::before {
  content: "\eb56";
}

.fas-fill-drip::before {
  content: "\eb57";
}

.fas-film::before {
  content: "\eb58";
}

.fas-filter::before {
  content: "\eb59";
}

.fas-fingerprint::before {
  content: "\eb5a";
}

.fas-fire::before {
  content: "\eb5b";
}

.fas-fire-alt::before {
  content: "\eb5c";
}

.fas-fire-extinguisher::before {
  content: "\eb5d";
}

.fas-first-aid::before {
  content: "\eb5e";
}

.fas-fish::before {
  content: "\eb5f";
}

.fas-fist-raised::before {
  content: "\eb60";
}

.fas-flag::before {
  content: "\eb61";
}

.fas-flag-checkered::before {
  content: "\eb62";
}

.fas-flag-usa::before {
  content: "\eb63";
}

.fas-flask::before {
  content: "\eb64";
}

.fas-flushed::before {
  content: "\eb65";
}

.fas-folder::before {
  content: "\eb66";
}

.fas-folder-minus::before {
  content: "\eb67";
}

.fas-folder-open::before {
  content: "\eb68";
}

.fas-folder-plus::before {
  content: "\eb69";
}

.fas-font::before {
  content: "\eb6a";
}

.fas-football-ball::before {
  content: "\eb6b";
}

.fas-forward::before {
  content: "\eb6c";
}

.fas-frog::before {
  content: "\eb6d";
}

.fas-frown::before {
  content: "\eb6e";
}

.fas-frown-open::before {
  content: "\eb6f";
}

.fas-funnel-dollar::before {
  content: "\eb70";
}

.fas-futbol::before {
  content: "\eb71";
}

.fas-gamepad::before {
  content: "\eb72";
}

.fas-gas-pump::before {
  content: "\eb73";
}

.fas-gavel::before {
  content: "\eb74";
}

.fas-gem::before {
  content: "\eb75";
}

.fas-genderless::before {
  content: "\eb76";
}

.fas-ghost::before {
  content: "\eb77";
}

.fas-gift::before {
  content: "\eb78";
}

.fas-gifts::before {
  content: "\eb79";
}

.fas-glass-cheers::before {
  content: "\eb7a";
}

.fas-glass-martini::before {
  content: "\eb7b";
}

.fas-glass-martini-alt::before {
  content: "\eb7c";
}

.fas-glass-whiskey::before {
  content: "\eb7d";
}

.fas-glasses::before {
  content: "\eb7e";
}

.fas-globe::before {
  content: "\eb7f";
}

.fas-globe-africa::before {
  content: "\eb80";
}

.fas-globe-americas::before {
  content: "\eb81";
}

.fas-globe-asia::before {
  content: "\eb82";
}

.fas-globe-europe::before {
  content: "\eb83";
}

.fas-golf-ball::before {
  content: "\eb84";
}

.fas-gopuram::before {
  content: "\eb85";
}

.fas-graduation-cap::before {
  content: "\eb86";
}

.fas-greater-than::before {
  content: "\eb87";
}

.fas-greater-than-equal::before {
  content: "\eb88";
}

.fas-grimace::before {
  content: "\eb89";
}

.fas-grin::before {
  content: "\eb8a";
}

.fas-grin-alt::before {
  content: "\eb8b";
}

.fas-grin-beam::before {
  content: "\eb8c";
}

.fas-grin-beam-sweat::before {
  content: "\eb8d";
}

.fas-grin-hearts::before {
  content: "\eb8e";
}

.fas-grin-squint::before {
  content: "\eb8f";
}

.fas-grin-squint-tears::before {
  content: "\eb90";
}

.fas-grin-stars::before {
  content: "\eb91";
}

.fas-grin-tears::before {
  content: "\eb92";
}

.fas-grin-tongue::before {
  content: "\eb93";
}

.fas-grin-tongue-squint::before {
  content: "\eb94";
}

.fas-grin-tongue-wink::before {
  content: "\eb95";
}

.fas-grin-wink::before {
  content: "\eb96";
}

.fas-grip-horizontal::before {
  content: "\eb97";
}

.fas-grip-lines::before {
  content: "\eb98";
}

.fas-grip-lines-vertical::before {
  content: "\eb99";
}

.fas-grip-vertical::before {
  content: "\eb9a";
}

.fas-guitar::before {
  content: "\eb9b";
}

.fas-h-square::before {
  content: "\eb9c";
}

.fas-hamburger::before {
  content: "\eb9d";
}

.fas-hammer::before {
  content: "\eb9e";
}

.fas-hamsa::before {
  content: "\eb9f";
}

.fas-hand-holding::before {
  content: "\eba0";
}

.fas-hand-holding-heart::before {
  content: "\eba1";
}

.fas-hand-holding-medical::before {
  content: "\eba2";
}

.fas-hand-holding-usd::before {
  content: "\eba3";
}

.fas-hand-holding-water::before {
  content: "\eba4";
}

.fas-hand-lizard::before {
  content: "\eba5";
}

.fas-hand-middle-finger::before {
  content: "\eba6";
}

.fas-hand-paper::before {
  content: "\eba7";
}

.fas-hand-peace::before {
  content: "\eba8";
}

.fas-hand-point-down::before {
  content: "\eba9";
}

.fas-hand-point-left::before {
  content: "\ebaa";
}

.fas-hand-point-right::before {
  content: "\ebab";
}

.fas-hand-point-up::before {
  content: "\ebac";
}

.fas-hand-pointer::before {
  content: "\ebad";
}

.fas-hand-rock::before {
  content: "\ebae";
}

.fas-hand-scissors::before {
  content: "\ebaf";
}

.fas-hand-sparkles::before {
  content: "\ebb0";
}

.fas-hand-spock::before {
  content: "\ebb1";
}

.fas-hands::before {
  content: "\ebb2";
}

.fas-hands-helping::before {
  content: "\ebb3";
}

.fas-hands-wash::before {
  content: "\ebb4";
}

.fas-handshake::before {
  content: "\ebb5";
}

.fas-handshake-alt-slash::before {
  content: "\ebb6";
}

.fas-handshake-slash::before {
  content: "\ebb7";
}

.fas-hanukiah::before {
  content: "\ebb8";
}

.fas-hard-hat::before {
  content: "\ebb9";
}

.fas-hashtag::before {
  content: "\ebba";
}

.fas-hat-cowboy::before {
  content: "\ebbb";
}

.fas-hat-cowboy-side::before {
  content: "\ebbc";
}

.fas-hat-wizard::before {
  content: "\ebbd";
}

.fas-hdd::before {
  content: "\ebbe";
}

.fas-head-side-cough::before {
  content: "\ebbf";
}

.fas-head-side-cough-slash::before {
  content: "\ebc0";
}

.fas-head-side-mask::before {
  content: "\ebc1";
}

.fas-head-side-virus::before {
  content: "\ebc2";
}

.fas-heading::before {
  content: "\ebc3";
}

.fas-headphones::before {
  content: "\ebc4";
}

.fas-headphones-alt::before {
  content: "\ebc5";
}

.fas-headset::before {
  content: "\ebc6";
}

.fas-heart::before {
  content: "\ebc7";
}

.fas-heart-broken::before {
  content: "\ebc8";
}

.fas-heartbeat::before {
  content: "\ebc9";
}

.fas-helicopter::before {
  content: "\ebca";
}

.fas-highlighter::before {
  content: "\ebcb";
}

.fas-hiking::before {
  content: "\ebcc";
}

.fas-hippo::before {
  content: "\ebcd";
}

.fas-history::before {
  content: "\ebce";
}

.fas-hockey-puck::before {
  content: "\ebcf";
}

.fas-holly-berry::before {
  content: "\ebd0";
}

.fas-home::before {
  content: "\ebd1";
}

.fas-horse::before {
  content: "\ebd2";
}

.fas-horse-head::before {
  content: "\ebd3";
}

.fas-hospital::before {
  content: "\ebd4";
}

.fas-hospital-alt::before {
  content: "\ebd5";
}

.fas-hospital-symbol::before {
  content: "\ebd6";
}

.fas-hospital-user::before {
  content: "\ebd7";
}

.fas-hot-tub::before {
  content: "\ebd8";
}

.fas-hotdog::before {
  content: "\ebd9";
}

.fas-hotel::before {
  content: "\ebda";
}

.fas-hourglass::before {
  content: "\ebdb";
}

.fas-hourglass-end::before {
  content: "\ebdc";
}

.fas-hourglass-half::before {
  content: "\ebdd";
}

.fas-hourglass-start::before {
  content: "\ebde";
}

.fas-house-damage::before {
  content: "\ebdf";
}

.fas-house-user::before {
  content: "\ebe0";
}

.fas-hryvnia::before {
  content: "\ebe1";
}

.fas-i-cursor::before {
  content: "\ebe2";
}

.fas-ice-cream::before {
  content: "\ebe3";
}

.fas-icicles::before {
  content: "\ebe4";
}

.fas-icons::before {
  content: "\ebe5";
}

.fas-id-badge::before {
  content: "\ebe6";
}

.fas-id-card::before {
  content: "\ebe7";
}

.fas-id-card-alt::before {
  content: "\ebe8";
}

.fas-igloo::before {
  content: "\ebe9";
}

.fas-image::before {
  content: "\ebea";
}

.fas-images::before {
  content: "\ebeb";
}

.fas-inbox::before {
  content: "\ebec";
}

.fas-indent::before {
  content: "\ebed";
}

.fas-industry::before {
  content: "\ebee";
}

.fas-infinity::before {
  content: "\ebef";
}

.fas-info::before {
  content: "\ebf0";
}

.fas-info-circle::before {
  content: "\ebf1";
}

.fas-italic::before {
  content: "\ebf2";
}

.fas-jedi::before {
  content: "\ebf3";
}

.fas-joint::before {
  content: "\ebf4";
}

.fas-journal-whills::before {
  content: "\ebf5";
}

.fas-kaaba::before {
  content: "\ebf6";
}

.fas-key::before {
  content: "\ebf7";
}

.fas-keyboard::before {
  content: "\ebf8";
}

.fas-khanda::before {
  content: "\ebf9";
}

.fas-kiss::before {
  content: "\ebfa";
}

.fas-kiss-beam::before {
  content: "\ebfb";
}

.fas-kiss-wink-heart::before {
  content: "\ebfc";
}

.fas-kiwi-bird::before {
  content: "\ebfd";
}

.fas-landmark::before {
  content: "\ebfe";
}

.fas-language::before {
  content: "\ebff";
}

.fas-laptop::before {
  content: "\ec00";
}

.fas-laptop-code::before {
  content: "\ec01";
}

.fas-laptop-house::before {
  content: "\ec02";
}

.fas-laptop-medical::before {
  content: "\ec03";
}

.fas-laugh::before {
  content: "\ec04";
}

.fas-laugh-beam::before {
  content: "\ec05";
}

.fas-laugh-squint::before {
  content: "\ec06";
}

.fas-laugh-wink::before {
  content: "\ec07";
}

.fas-layer-group::before {
  content: "\ec08";
}

.fas-leaf::before {
  content: "\ec09";
}

.fas-lemon::before {
  content: "\ec0a";
}

.fas-less-than::before {
  content: "\ec0b";
}

.fas-less-than-equal::before {
  content: "\ec0c";
}

.fas-level-down-alt::before {
  content: "\ec0d";
}

.fas-level-up-alt::before {
  content: "\ec0e";
}

.fas-life-ring::before {
  content: "\ec0f";
}

.fas-lightbulb::before {
  content: "\ec10";
}

.fas-link::before {
  content: "\ec11";
}

.fas-lira-sign::before {
  content: "\ec12";
}

.fas-list::before {
  content: "\ec13";
}

.fas-list-alt::before {
  content: "\ec14";
}

.fas-list-ol::before {
  content: "\ec15";
}

.fas-list-ul::before {
  content: "\ec16";
}

.fas-location-arrow::before {
  content: "\ec17";
}

.fas-lock::before {
  content: "\ec18";
}

.fas-lock-open::before {
  content: "\ec19";
}

.fas-long-arrow-alt-down::before {
  content: "\ec1a";
}

.fas-long-arrow-alt-left::before {
  content: "\ec1b";
}

.fas-long-arrow-alt-right::before {
  content: "\ec1c";
}

.fas-long-arrow-alt-up::before {
  content: "\ec1d";
}

.fas-low-vision::before {
  content: "\ec1e";
}

.fas-luggage-cart::before {
  content: "\ec1f";
}

.fas-lungs::before {
  content: "\ec20";
}

.fas-lungs-virus::before {
  content: "\ec21";
}

.fas-magic::before {
  content: "\ec22";
}

.fas-magnet::before {
  content: "\ec23";
}

.fas-mail-bulk::before {
  content: "\ec24";
}

.fas-male::before {
  content: "\ec25";
}

.fas-map::before {
  content: "\ec26";
}

.fas-map-marked::before {
  content: "\ec27";
}

.fas-map-marked-alt::before {
  content: "\ec28";
}

.fas-map-marker::before {
  content: "\ec29";
}

.fas-map-marker-alt::before {
  content: "\ec2a";
}

.fas-map-pin::before {
  content: "\ec2b";
}

.fas-map-signs::before {
  content: "\ec2c";
}

.fas-marker::before {
  content: "\ec2d";
}

.fas-mars::before {
  content: "\ec2e";
}

.fas-mars-double::before {
  content: "\ec2f";
}

.fas-mars-stroke::before {
  content: "\ec30";
}

.fas-mars-stroke-h::before {
  content: "\ec31";
}

.fas-mars-stroke-v::before {
  content: "\ec32";
}

.fas-mask::before {
  content: "\ec33";
}

.fas-medal::before {
  content: "\ec34";
}

.fas-medkit::before {
  content: "\ec35";
}

.fas-meh::before {
  content: "\ec36";
}

.fas-meh-blank::before {
  content: "\ec37";
}

.fas-meh-rolling-eyes::before {
  content: "\ec38";
}

.fas-memory::before {
  content: "\ec39";
}

.fas-menorah::before {
  content: "\ec3a";
}

.fas-mercury::before {
  content: "\ec3b";
}

.fas-meteor::before {
  content: "\ec3c";
}

.fas-microchip::before {
  content: "\ec3d";
}

.fas-microphone::before {
  content: "\ec3e";
}

.fas-microphone-alt::before {
  content: "\ec3f";
}

.fas-microphone-alt-slash::before {
  content: "\ec40";
}

.fas-microphone-slash::before {
  content: "\ec41";
}

.fas-microscope::before {
  content: "\ec42";
}

.fas-minus::before {
  content: "\ec43";
}

.fas-minus-circle::before {
  content: "\ec44";
}

.fas-minus-square::before {
  content: "\ec45";
}

.fas-mitten::before {
  content: "\ec46";
}

.fas-mobile::before {
  content: "\ec47";
}

.fas-mobile-alt::before {
  content: "\ec48";
}

.fas-money-bill::before {
  content: "\ec49";
}

.fas-money-bill-alt::before {
  content: "\ec4a";
}

.fas-money-bill-wave::before {
  content: "\ec4b";
}

.fas-money-bill-wave-alt::before {
  content: "\ec4c";
}

.fas-money-check::before {
  content: "\ec4d";
}

.fas-money-check-alt::before {
  content: "\ec4e";
}

.fas-monument::before {
  content: "\ec4f";
}

.fas-moon::before {
  content: "\ec50";
}

.fas-mortar-pestle::before {
  content: "\ec51";
}

.fas-mosque::before {
  content: "\ec52";
}

.fas-motorcycle::before {
  content: "\ec53";
}

.fas-mountain::before {
  content: "\ec54";
}

.fas-mouse::before {
  content: "\ec55";
}

.fas-mouse-pointer::before {
  content: "\ec56";
}

.fas-mug-hot::before {
  content: "\ec57";
}

.fas-music::before {
  content: "\ec58";
}

.fas-network-wired::before {
  content: "\ec59";
}

.fas-neuter::before {
  content: "\ec5a";
}

.fas-newspaper::before {
  content: "\ec5b";
}

.fas-not-equal::before {
  content: "\ec5c";
}

.fas-notes-medical::before {
  content: "\ec5d";
}

.fas-object-group::before {
  content: "\ec5e";
}

.fas-object-ungroup::before {
  content: "\ec5f";
}

.fas-oil-can::before {
  content: "\ec60";
}

.fas-om::before {
  content: "\ec61";
}

.fas-otter::before {
  content: "\ec62";
}

.fas-outdent::before {
  content: "\ec63";
}

.fas-pager::before {
  content: "\ec64";
}

.fas-paint-brush::before {
  content: "\ec65";
}

.fas-paint-roller::before {
  content: "\ec66";
}

.fas-palette::before {
  content: "\ec67";
}

.fas-pallet::before {
  content: "\ec68";
}

.fas-paper-plane::before {
  content: "\ec69";
}

.fas-paperclip::before {
  content: "\ec6a";
}

.fas-parachute-box::before {
  content: "\ec6b";
}

.fas-paragraph::before {
  content: "\ec6c";
}

.fas-parking::before {
  content: "\ec6d";
}

.fas-passport::before {
  content: "\ec6e";
}

.fas-pastafarianism::before {
  content: "\ec6f";
}

.fas-paste::before {
  content: "\ec70";
}

.fas-pause::before {
  content: "\ec71";
}

.fas-pause-circle::before {
  content: "\ec72";
}

.fas-paw::before {
  content: "\ec73";
}

.fas-peace::before {
  content: "\ec74";
}

.fas-pen::before {
  content: "\ec75";
}

.fas-pen-alt::before {
  content: "\ec76";
}

.fas-pen-fancy::before {
  content: "\ec77";
}

.fas-pen-nib::before {
  content: "\ec78";
}

.fas-pen-square::before {
  content: "\ec79";
}

.fas-pencil-alt::before {
  content: "\ec7a";
}

.fas-pencil-ruler::before {
  content: "\ec7b";
}

.fas-people-arrows::before {
  content: "\ec7c";
}

.fas-people-carry::before {
  content: "\ec7d";
}

.fas-pepper-hot::before {
  content: "\ec7e";
}

.fas-percent::before {
  content: "\ec7f";
}

.fas-percentage::before {
  content: "\ec80";
}

.fas-person-booth::before {
  content: "\ec81";
}

.fas-phone::before {
  content: "\ec82";
}

.fas-phone-alt::before {
  content: "\ec83";
}

.fas-phone-slash::before {
  content: "\ec84";
}

.fas-phone-square::before {
  content: "\ec85";
}

.fas-phone-square-alt::before {
  content: "\ec86";
}

.fas-phone-volume::before {
  content: "\ec87";
}

.fas-photo-video::before {
  content: "\ec88";
}

.fas-piggy-bank::before {
  content: "\ec89";
}

.fas-pills::before {
  content: "\ec8a";
}

.fas-pizza-slice::before {
  content: "\ec8b";
}

.fas-place-of-worship::before {
  content: "\ec8c";
}

.fas-plane::before {
  content: "\ec8d";
}

.fas-plane-arrival::before {
  content: "\ec8e";
}

.fas-plane-departure::before {
  content: "\ec8f";
}

.fas-plane-slash::before {
  content: "\ec90";
}

.fas-play::before {
  content: "\ec91";
}

.fas-play-circle::before {
  content: "\ec92";
}

.fas-plug::before {
  content: "\ec93";
}

.fas-plus::before {
  content: "\ec94";
}

.fas-plus-circle::before {
  content: "\ec95";
}

.fas-plus-square::before {
  content: "\ec96";
}

.fas-podcast::before {
  content: "\ec97";
}

.fas-poll::before {
  content: "\ec98";
}

.fas-poll-h::before {
  content: "\ec99";
}

.fas-poo::before {
  content: "\ec9a";
}

.fas-poo-storm::before {
  content: "\ec9b";
}

.fas-poop::before {
  content: "\ec9c";
}

.fas-portrait::before {
  content: "\ec9d";
}

.fas-pound-sign::before {
  content: "\ec9e";
}

.fas-power-off::before {
  content: "\ec9f";
}

.fas-pray::before {
  content: "\eca0";
}

.fas-praying-hands::before {
  content: "\eca1";
}

.fas-prescription::before {
  content: "\eca2";
}

.fas-prescription-bottle::before {
  content: "\eca3";
}

.fas-prescription-bottle-alt::before {
  content: "\eca4";
}

.fas-print::before {
  content: "\eca5";
}

.fas-procedures::before {
  content: "\eca6";
}

.fas-project-diagram::before {
  content: "\eca7";
}

.fas-pump-medical::before {
  content: "\eca8";
}

.fas-pump-soap::before {
  content: "\eca9";
}

.fas-puzzle-piece::before {
  content: "\ecaa";
}

.fas-qrcode::before {
  content: "\ecab";
}

.fas-question::before {
  content: "\ecac";
}

.fas-question-circle::before {
  content: "\ecad";
}

.fas-quidditch::before {
  content: "\ecae";
}

.fas-quote-left::before {
  content: "\ecaf";
}

.fas-quote-right::before {
  content: "\ecb0";
}

.fas-quran::before {
  content: "\ecb1";
}

.fas-radiation::before {
  content: "\ecb2";
}

.fas-radiation-alt::before {
  content: "\ecb3";
}

.fas-rainbow::before {
  content: "\ecb4";
}

.fas-random::before {
  content: "\ecb5";
}

.fas-receipt::before {
  content: "\ecb6";
}

.fas-record-vinyl::before {
  content: "\ecb7";
}

.fas-recycle::before {
  content: "\ecb8";
}

.fas-redo::before {
  content: "\ecb9";
}

.fas-redo-alt::before {
  content: "\ecba";
}

.fas-registered::before {
  content: "\ecbb";
}

.fas-remove-format::before {
  content: "\ecbc";
}

.fas-reply::before {
  content: "\ecbd";
}

.fas-reply-all::before {
  content: "\ecbe";
}

.fas-republican::before {
  content: "\ecbf";
}

.fas-restroom::before {
  content: "\ecc0";
}

.fas-retweet::before {
  content: "\ecc1";
}

.fas-ribbon::before {
  content: "\ecc2";
}

.fas-ring::before {
  content: "\ecc3";
}

.fas-road::before {
  content: "\ecc4";
}

.fas-robot::before {
  content: "\ecc5";
}

.fas-rocket::before {
  content: "\ecc6";
}

.fas-route::before {
  content: "\ecc7";
}

.fas-rss::before {
  content: "\ecc8";
}

.fas-rss-square::before {
  content: "\ecc9";
}

.fas-ruble-sign::before {
  content: "\ecca";
}

.fas-ruler::before {
  content: "\eccb";
}

.fas-ruler-combined::before {
  content: "\eccc";
}

.fas-ruler-horizontal::before {
  content: "\eccd";
}

.fas-ruler-vertical::before {
  content: "\ecce";
}

.fas-running::before {
  content: "\eccf";
}

.fas-rupee-sign::before {
  content: "\ecd0";
}

.fas-sad-cry::before {
  content: "\ecd1";
}

.fas-sad-tear::before {
  content: "\ecd2";
}

.fas-satellite::before {
  content: "\ecd3";
}

.fas-satellite-dish::before {
  content: "\ecd4";
}

.fas-save::before {
  content: "\ecd5";
}

.fas-school::before {
  content: "\ecd6";
}

.fas-screwdriver::before {
  content: "\ecd7";
}

.fas-scroll::before {
  content: "\ecd8";
}

.fas-sd-card::before {
  content: "\ecd9";
}

.fas-search::before {
  content: "\ecda";
}

.fas-search-dollar::before {
  content: "\ecdb";
}

.fas-search-location::before {
  content: "\ecdc";
}

.fas-search-minus::before {
  content: "\ecdd";
}

.fas-search-plus::before {
  content: "\ecde";
}

.fas-seedling::before {
  content: "\ecdf";
}

.fas-server::before {
  content: "\ece0";
}

.fas-shapes::before {
  content: "\ece1";
}

.fas-share::before {
  content: "\ece2";
}

.fas-share-alt::before {
  content: "\ece3";
}

.fas-share-alt-square::before {
  content: "\ece4";
}

.fas-share-square::before {
  content: "\ece5";
}

.fas-shekel-sign::before {
  content: "\ece6";
}

.fas-shield-alt::before {
  content: "\ece7";
}

.fas-shield-virus::before {
  content: "\ece8";
}

.fas-ship::before {
  content: "\ece9";
}

.fas-shipping-fast::before {
  content: "\ecea";
}

.fas-shoe-prints::before {
  content: "\eceb";
}

.fas-shopping-bag::before {
  content: "\ecec";
}

.fas-shopping-basket::before {
  content: "\eced";
}

.fas-shopping-cart::before {
  content: "\ecee";
}

.fas-shower::before {
  content: "\ecef";
}

.fas-shuttle-van::before {
  content: "\ecf0";
}

.fas-sign::before {
  content: "\ecf1";
}

.fas-sign-in-alt::before {
  content: "\ecf2";
}

.fas-sign-language::before {
  content: "\ecf3";
}

.fas-sign-out-alt::before {
  content: "\ecf4";
}

.fas-signal::before {
  content: "\ecf5";
}

.fas-signature::before {
  content: "\ecf6";
}

.fas-sim-card::before {
  content: "\ecf7";
}

.fas-sitemap::before {
  content: "\ecf8";
}

.fas-skating::before {
  content: "\ecf9";
}

.fas-skiing::before {
  content: "\ecfa";
}

.fas-skiing-nordic::before {
  content: "\ecfb";
}

.fas-skull::before {
  content: "\ecfc";
}

.fas-skull-crossbones::before {
  content: "\ecfd";
}

.fas-slash::before {
  content: "\ecfe";
}

.fas-sleigh::before {
  content: "\ecff";
}

.fas-sliders-h::before {
  content: "\ed00";
}

.fas-smile::before {
  content: "\ed01";
}

.fas-smile-beam::before {
  content: "\ed02";
}

.fas-smile-wink::before {
  content: "\ed03";
}

.fas-smog::before {
  content: "\ed04";
}

.fas-smoking::before {
  content: "\ed05";
}

.fas-smoking-ban::before {
  content: "\ed06";
}

.fas-sms::before {
  content: "\ed07";
}

.fas-snowboarding::before {
  content: "\ed08";
}

.fas-snowflake::before {
  content: "\ed09";
}

.fas-snowman::before {
  content: "\ed0a";
}

.fas-snowplow::before {
  content: "\ed0b";
}

.fas-soap::before {
  content: "\ed0c";
}

.fas-socks::before {
  content: "\ed0d";
}

.fas-solar-panel::before {
  content: "\ed0e";
}

.fas-sort::before {
  content: "\ed0f";
}

.fas-sort-alpha-down::before {
  content: "\ed10";
}

.fas-sort-alpha-down-alt::before {
  content: "\ed11";
}

.fas-sort-alpha-up::before {
  content: "\ed12";
}

.fas-sort-alpha-up-alt::before {
  content: "\ed13";
}

.fas-sort-amount-down::before {
  content: "\ed14";
}

.fas-sort-amount-down-alt::before {
  content: "\ed15";
}

.fas-sort-amount-up::before {
  content: "\ed16";
}

.fas-sort-amount-up-alt::before {
  content: "\ed17";
}

.fas-sort-down::before {
  content: "\ed18";
}

.fas-sort-numeric-down::before {
  content: "\ed19";
}

.fas-sort-numeric-down-alt::before {
  content: "\ed1a";
}

.fas-sort-numeric-up::before {
  content: "\ed1b";
}

.fas-sort-numeric-up-alt::before {
  content: "\ed1c";
}

.fas-sort-up::before {
  content: "\ed1d";
}

.fas-spa::before {
  content: "\ed1e";
}

.fas-space-shuttle::before {
  content: "\ed1f";
}

.fas-spell-check::before {
  content: "\ed20";
}

.fas-spider::before {
  content: "\ed21";
}

.fas-spinner::before {
  content: "\ed22";
}

.fas-splotch::before {
  content: "\ed23";
}

.fas-spray-can::before {
  content: "\ed24";
}

.fas-square::before {
  content: "\ed25";
}

.fas-square-full::before {
  content: "\ed26";
}

.fas-square-root-alt::before {
  content: "\ed27";
}

.fas-stamp::before {
  content: "\ed28";
}

.fas-star::before {
  content: "\ed29";
}

.fas-star-and-crescent::before {
  content: "\ed2a";
}

.fas-star-half::before {
  content: "\ed2b";
}

.fas-star-half-alt::before {
  content: "\ed2c";
}

.fas-star-of-david::before {
  content: "\ed2d";
}

.fas-star-of-life::before {
  content: "\ed2e";
}

.fas-step-backward::before {
  content: "\ed2f";
}

.fas-step-forward::before {
  content: "\ed30";
}

.fas-stethoscope::before {
  content: "\ed31";
}

.fas-sticky-note::before {
  content: "\ed32";
}

.fas-stop::before {
  content: "\ed33";
}

.fas-stop-circle::before {
  content: "\ed34";
}

.fas-stopwatch::before {
  content: "\ed35";
}

.fas-stopwatch-20::before {
  content: "\ed36";
}

.fas-store::before {
  content: "\ed37";
}

.fas-store-alt::before {
  content: "\ed38";
}

.fas-store-alt-slash::before {
  content: "\ed39";
}

.fas-store-slash::before {
  content: "\ed3a";
}

.fas-stream::before {
  content: "\ed3b";
}

.fas-street-view::before {
  content: "\ed3c";
}

.fas-strikethrough::before {
  content: "\ed3d";
}

.fas-stroopwafel::before {
  content: "\ed3e";
}

.fas-subscript::before {
  content: "\ed3f";
}

.fas-subway::before {
  content: "\ed40";
}

.fas-suitcase::before {
  content: "\ed41";
}

.fas-suitcase-rolling::before {
  content: "\ed42";
}

.fas-sun::before {
  content: "\ed43";
}

.fas-superscript::before {
  content: "\ed44";
}

.fas-surprise::before {
  content: "\ed45";
}

.fas-swatchbook::before {
  content: "\ed46";
}

.fas-swimmer::before {
  content: "\ed47";
}

.fas-swimming-pool::before {
  content: "\ed48";
}

.fas-synagogue::before {
  content: "\ed49";
}

.fas-sync::before {
  content: "\ed4a";
}

.fas-sync-alt::before {
  content: "\ed4b";
}

.fas-syringe::before {
  content: "\ed4c";
}

.fas-table::before {
  content: "\ed4d";
}

.fas-table-tennis::before {
  content: "\ed4e";
}

.fas-tablet::before {
  content: "\ed4f";
}

.fas-tablet-alt::before {
  content: "\ed50";
}

.fas-tablets::before {
  content: "\ed51";
}

.fas-tachometer-alt::before {
  content: "\ed52";
}

.fas-tag::before {
  content: "\ed53";
}

.fas-tags::before {
  content: "\ed54";
}

.fas-tape::before {
  content: "\ed55";
}

.fas-tasks::before {
  content: "\ed56";
}

.fas-taxi::before {
  content: "\ed57";
}

.fas-teeth::before {
  content: "\ed58";
}

.fas-teeth-open::before {
  content: "\ed59";
}

.fas-temperature-high::before {
  content: "\ed5a";
}

.fas-temperature-low::before {
  content: "\ed5b";
}

.fas-tenge::before {
  content: "\ed5c";
}

.fas-terminal::before {
  content: "\ed5d";
}

.fas-text-height::before {
  content: "\ed5e";
}

.fas-text-width::before {
  content: "\ed5f";
}

.fas-th::before {
  content: "\ed60";
}

.fas-th-large::before {
  content: "\ed61";
}

.fas-th-list::before {
  content: "\ed62";
}

.fas-theater-masks::before {
  content: "\ed63";
}

.fas-thermometer::before {
  content: "\ed64";
}

.fas-thermometer-empty::before {
  content: "\ed65";
}

.fas-thermometer-full::before {
  content: "\ed66";
}

.fas-thermometer-half::before {
  content: "\ed67";
}

.fas-thermometer-quarter::before {
  content: "\ed68";
}

.fas-thermometer-three-quarters::before {
  content: "\ed69";
}

.fas-thumbs-down::before {
  content: "\ed6a";
}

.fas-thumbs-up::before {
  content: "\ed6b";
}

.fas-thumbtack::before {
  content: "\ed6c";
}

.fas-ticket-alt::before {
  content: "\ed6d";
}

.fas-times::before {
  content: "\ed6e";
}

.fas-times-circle::before {
  content: "\ed6f";
}

.fas-tint::before {
  content: "\ed70";
}

.fas-tint-slash::before {
  content: "\ed71";
}

.fas-tired::before {
  content: "\ed72";
}

.fas-toggle-off::before {
  content: "\ed73";
}

.fas-toggle-on::before {
  content: "\ed74";
}

.fas-toilet::before {
  content: "\ed75";
}

.fas-toilet-paper::before {
  content: "\ed76";
}

.fas-toilet-paper-slash::before {
  content: "\ed77";
}

.fas-toolbox::before {
  content: "\ed78";
}

.fas-tools::before {
  content: "\ed79";
}

.fas-tooth::before {
  content: "\ed7a";
}

.fas-torah::before {
  content: "\ed7b";
}

.fas-torii-gate::before {
  content: "\ed7c";
}

.fas-tractor::before {
  content: "\ed7d";
}

.fas-trademark::before {
  content: "\ed7e";
}

.fas-traffic-light::before {
  content: "\ed7f";
}

.fas-trailer::before {
  content: "\ed80";
}

.fas-train::before {
  content: "\ed81";
}

.fas-tram::before {
  content: "\ed82";
}

.fas-transgender::before {
  content: "\ed83";
}

.fas-transgender-alt::before {
  content: "\ed84";
}

.fas-trash::before {
  content: "\ed85";
}

.fas-trash-alt::before {
  content: "\ed86";
}

.fas-trash-restore::before {
  content: "\ed87";
}

.fas-trash-restore-alt::before {
  content: "\ed88";
}

.fas-tree::before {
  content: "\ed89";
}

.fas-trophy::before {
  content: "\ed8a";
}

.fas-truck::before {
  content: "\ed8b";
}

.fas-truck-loading::before {
  content: "\ed8c";
}

.fas-truck-monster::before {
  content: "\ed8d";
}

.fas-truck-moving::before {
  content: "\ed8e";
}

.fas-truck-pickup::before {
  content: "\ed8f";
}

.fas-tshirt::before {
  content: "\ed90";
}

.fas-tty::before {
  content: "\ed91";
}

.fas-tv::before {
  content: "\ed92";
}

.fas-umbrella::before {
  content: "\ed93";
}

.fas-umbrella-beach::before {
  content: "\ed94";
}

.fas-underline::before {
  content: "\ed95";
}

.fas-undo::before {
  content: "\ed96";
}

.fas-undo-alt::before {
  content: "\ed97";
}

.fas-universal-access::before {
  content: "\ed98";
}

.fas-university::before {
  content: "\ed99";
}

.fas-unlink::before {
  content: "\ed9a";
}

.fas-unlock::before {
  content: "\ed9b";
}

.fas-unlock-alt::before {
  content: "\ed9c";
}

.fas-upload::before {
  content: "\ed9d";
}

.fas-user::before {
  content: "\ed9e";
}

.fas-user-alt::before {
  content: "\ed9f";
}

.fas-user-alt-slash::before {
  content: "\eda0";
}

.fas-user-astronaut::before {
  content: "\eda1";
}

.fas-user-check::before {
  content: "\eda2";
}

.fas-user-circle::before {
  content: "\eda3";
}

.fas-user-clock::before {
  content: "\eda4";
}

.fas-user-cog::before {
  content: "\eda5";
}

.fas-user-edit::before {
  content: "\eda6";
}

.fas-user-friends::before {
  content: "\eda7";
}

.fas-user-graduate::before {
  content: "\eda8";
}

.fas-user-injured::before {
  content: "\eda9";
}

.fas-user-lock::before {
  content: "\edaa";
}

.fas-user-md::before {
  content: "\edab";
}

.fas-user-minus::before {
  content: "\edac";
}

.fas-user-ninja::before {
  content: "\edad";
}

.fas-user-nurse::before {
  content: "\edae";
}

.fas-user-plus::before {
  content: "\edaf";
}

.fas-user-secret::before {
  content: "\edb0";
}

.fas-user-shield::before {
  content: "\edb1";
}

.fas-user-slash::before {
  content: "\edb2";
}

.fas-user-tag::before {
  content: "\edb3";
}

.fas-user-tie::before {
  content: "\edb4";
}

.fas-user-times::before {
  content: "\edb5";
}

.fas-users::before {
  content: "\edb6";
}

.fas-users-cog::before {
  content: "\edb7";
}

.fas-utensil-spoon::before {
  content: "\edb8";
}

.fas-utensils::before {
  content: "\edb9";
}

.fas-vector-square::before {
  content: "\edba";
}

.fas-venus::before {
  content: "\edbb";
}

.fas-venus-double::before {
  content: "\edbc";
}

.fas-venus-mars::before {
  content: "\edbd";
}

.fas-vial::before {
  content: "\edbe";
}

.fas-vials::before {
  content: "\edbf";
}

.fas-video::before {
  content: "\edc0";
}

.fas-video-slash::before {
  content: "\edc1";
}

.fas-vihara::before {
  content: "\edc2";
}

.fas-virus::before {
  content: "\edc3";
}

.fas-virus-slash::before {
  content: "\edc4";
}

.fas-viruses::before {
  content: "\edc5";
}

.fas-voicemail::before {
  content: "\edc6";
}

.fas-volleyball-ball::before {
  content: "\edc7";
}

.fas-volume-down::before {
  content: "\edc8";
}

.fas-volume-mute::before {
  content: "\edc9";
}

.fas-volume-off::before {
  content: "\edca";
}

.fas-volume-up::before {
  content: "\edcb";
}

.fas-vote-yea::before {
  content: "\edcc";
}

.fas-vr-cardboard::before {
  content: "\edcd";
}

.fas-walking::before {
  content: "\edce";
}

.fas-wallet::before {
  content: "\edcf";
}

.fas-warehouse::before {
  content: "\edd0";
}

.fas-water::before {
  content: "\edd1";
}

.fas-wave-square::before {
  content: "\edd2";
}

.fas-weight::before {
  content: "\edd3";
}

.fas-weight-hanging::before {
  content: "\edd4";
}

.fas-wheelchair::before {
  content: "\edd5";
}

.fas-wifi::before {
  content: "\edd6";
}

.fas-wind::before {
  content: "\edd7";
}

.fas-window-close::before {
  content: "\edd8";
}

.fas-window-maximize::before {
  content: "\edd9";
}

.fas-window-minimize::before {
  content: "\edda";
}

.fas-window-restore::before {
  content: "\eddb";
}

.fas-wine-bottle::before {
  content: "\eddc";
}

.fas-wine-glass::before {
  content: "\eddd";
}

.fas-wine-glass-alt::before {
  content: "\edde";
}

.fas-won-sign::before {
  content: "\eddf";
}

.fas-wrench::before {
  content: "\ede0";
}

.fas-x-ray::before {
  content: "\ede1";
}

.fas-yen-sign::before {
  content: "\ede2";
}

.fas-yin-yang::before {
  content: "\ede3";
}
